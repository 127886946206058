import React, { useEffect, useState } from 'react'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import { SwiperOptions } from 'swiper/types'
import { Navigation, Pagination } from 'swiper/modules'
import { SbBlokData, storyblokEditable } from '@storyblok/react'
import ItemSectionHeadV2 from '@/v2/storyblok/items/ItemSectionHeadV2'
import ItemIconButtonV2 from '../items/ItemIconButtonV2'
import BaseImageV2 from '@/v2/base/BaseImageV2'
import UtilButtonV2 from '../utils/UtilButtonV2'
import 'swiper/css'
import classNames from 'classnames'

type Props = {
  blok: SbBlokData & ISbSectionStepsV2
}

const SectionStepsV2 = ({ blok }: Props) => {
  const itemsLength = blok.items.length
  const isSlideDesktopDisabled = itemsLength < 4

  const [swiperInstance, setSwiperInstance] = useState<SwiperClass | null>(null)
  const [isBeginning, setIsBeginning] = useState(true)
  const [isEnd, setIsEnd] = useState(false)

  const options: SwiperOptions = {
    preventInteractionOnTransition: true,
    centerInsufficientSlides: true,
    grabCursor: true,
    slidesPerView: 1.2,
    spaceBetween: 32,
    navigation: false,
    breakpoints: {
      1024: {
        grabCursor: !isSlideDesktopDisabled,
        allowTouchMove: !isSlideDesktopDisabled,
        slidesPerView: isSlideDesktopDisabled ? itemsLength : 3,
      },
    },
    modules: [Navigation, Pagination],
  }

  const nextSlide = () => {
    if (swiperInstance) {
      swiperInstance.slideNext()
    }
  }

  const prevSlide = () => {
    if (swiperInstance) {
      swiperInstance.slidePrev()
    }
  }

  useEffect(() => {
    if (swiperInstance) {
      swiperInstance.on('slideChange', () => {
        setIsBeginning(swiperInstance.isBeginning)
        setIsEnd(swiperInstance.isEnd)
      })
    }
  }, [swiperInstance])

  return (
    <div className="py-16 lg:py-24" {...storyblokEditable(blok)}>
      {!!blok.head?.[0] && (
        <div className="container-v2 mx-auto mb-16 grid gap-14 px-4 sm:gap-16">
          <ItemSectionHeadV2 blok={blok.head[0]} />
        </div>
      )}
      <div className="container-v2 hidden md:block xl:p-0">
        <Swiper
          key="steps-slider"
          {...options}
          onSwiper={(swiper) => setSwiperInstance(swiper)}
          className="mb-6 !overflow-visible"
        >
          {Array.isArray(blok.items) &&
            blok.items.map((item, index) => (
              <SwiperSlide
                {...storyblokEditable(item)}
                className="!flex !h-auto shrink-0 snap-center flex-col justify-between gap-7 overflow-hidden rounded-5xl border border-v2-foreground-static-tertiary bg-gradient-to-b from-v2-background-beige to-white"
                key={index + '-steps-slide'}
              >
                <div className="flex gap-5 p-10">
                  <span className="heading-3 text-v2-text-primary">{index + 1}</span>
                  <div className="flex flex-col gap-2">
                    <span className="heading-5 text-v2-text-primary">{item.title}</span>
                    <p className="body-3 whitespace-pre-line text-v2-text-secondary">{item.text}</p>
                  </div>
                </div>

                {!!item.image?.filename && (
                  <div className="h-64 w-full">
                    <BaseImageV2
                      src={item.image.filename}
                      focus={item.image.focus}
                      alt={item.image.alt}
                      objectCover={true}
                      breakPointsWidthMap={{ 0: 256 }}
                      className="h-full w-full [&_img]:h-full [&_img]:w-full [&_img]:object-contain [&_img]:lg:object-cover"
                    />
                  </div>
                )}
              </SwiperSlide>
            ))}
        </Swiper>

        {/* Bottom */}
        {/* hide Nav when there are only three slides on large screens*/}
        <div className={classNames('container-v2 mx-auto flex gap-6', { 'xl:hidden': isSlideDesktopDisabled })}>
          <div className="flex gap-3">
            <ItemIconButtonV2 ariaLabel="Slide Prev" icon="chevron-left-v2" onClick={prevSlide} disabled={isBeginning} />
            <ItemIconButtonV2 ariaLabel="Slide Next" icon="chevron-right-v2" onClick={nextSlide} disabled={isEnd} />
          </div>
          {!!blok.button?.length && (
            <div className="block">
              {blok.button.map((blok: SbBlokData & ISbUtilButtonV2, index: number) => (
                <UtilButtonV2 key={index} blok={blok} />
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="container-v2 flex flex-col gap-8 md:hidden">
        {blok.items.map((item, index) => (
          <div
            className="grid shrink-0 snap-center overflow-hidden rounded-5xl border border-v2-foreground-static-tertiary bg-gradient-to-b from-v2-background-beige to-white"
            key={index + '-steps-slide'}
          >
            <div className="flex gap-4 p-8">
              <span className="heading-3">{index + 1}</span>
              <div className="flex flex-col gap-2">
                <span className="heading-5 text-v2-text-primary">{item.title}</span>
                <p className="body-3 whitespace-pre-line text-v2-text-secondary">{item.text}</p>
              </div>
            </div>
            {!!item.image?.filename && (
              <BaseImageV2
                objectCover={false}
                src={item.image.filename}
                alt={item.image.alt}
                breakPointsWidthMap={{ 0: 767 }}
                className="h-full w-full [&_img]:h-full [&_img]:w-full"
              />
            )}
          </div>
        ))}

        {!!blok.button?.length && (
          <div className="block">
            {blok.button.map((blok: SbBlokData & ISbUtilButtonV2, index: number) => (
              <UtilButtonV2 key={index} blok={blok} />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default SectionStepsV2
